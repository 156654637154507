<template>
  <CardToolbox>
    <sdPageHeader title="Scripts">
      <template #subTitle>
        <span class="title-counter">{{ count }} script{{ count > 0 && count < 2 ? '' : 's' }}</span>
<!--        <sdAutoComplete :dataSource="searchData" width="100%" placeholder="Zoeken op naam" patterns/>-->
      </template>
      <template #buttons>
        <ScriptAdd v-if="$can('create', 'script')"/>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <ScriptTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import ScriptTable from './component/ScriptTable';
import {computed, ref, defineComponent, onMounted} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox} from '../styled';
import ScriptAdd from "./ScriptAdd";

const ScriptList = defineComponent({
  name: 'ScriptList',
  components: {ScriptAdd, Main, CardToolbox, ScriptTable},
  data() {
    return {
      showEditDrawer: false,
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.script.count);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);

    onMounted(() => {
      dispatch('getScripts', {page: state.script.currentPage, pageSize: state.script.pageSize});
    });

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      count,
    };
  },
});

export default ScriptList;
</script>
