<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="scriptTableColumns"
    :dataSource="scriptTableData"
    :loading="loadingScripts"
    :pagination="{
          defaultPageSize: 100,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {useAbility} from "@casl/vue";

const scriptTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Aangemaakt op',
  dataIndex: 'createTimestamp',
  key: 'createTimestamp',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const ScriptListTable = defineComponent({
 name: 'ScriptListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const {state, dispatch} = useStore();
  const scripts = computed(() => state.script.scripts);
  const loadingScripts = computed(() => state.script.loadingScripts);
  const currentPage = computed(() => state.script.currentPage);
  const count = computed(() => state.script.count);
  const router = useRouter();
  const {matched} = useRoute();
  const {path} = matched[1];
  const {can} = useAbility();
  const handleDelete = (id) => {
   dispatch('deleteScript', id);
  };
  const handleEdit = (id) => {
   router.push(`${path}/${id}`);
  };
  const scriptTableData = computed(() =>
   scripts.value.map((script) => {
    const {id, name, createTimestamp} = script;

    return {
     key: id,
     id: id,
     name: (
      <div class="user-info">

       <figcaption>
        <sdHeading class="user-name" as="h6">
         {name}
        </sdHeading>
       </figcaption>
      </div>
     ),
     createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
     action: (
      <div className="table-actions">
       {can('view', 'script') ? (
        <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
         <sdFeatherIcons type="edit" size="16"/>
        </sdButton>
       ) : ('')}
       {can('delete', 'script') ? (
        <a-popconfirm
         title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
         ok-text="Ja"
         cancel-text="Nee"
         placement="leftTop"
         onConfirm={() => handleDelete(id)}
        >
         <sdButton className="btn-icon" type="default" to="#" shape="circle">
          <sdFeatherIcons type="trash-2" size="16"/>
         </sdButton>
        </a-popconfirm>
       ) : ('')}
      </div>
     ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled script', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageScript', event.current);
   await dispatch('getScripts', {page: event.current, pageSize: state.sender.pageSize});
  }

  return {scriptTableColumns, scriptTableData, rowSelection, loadingScripts, count, currentPage, paginationChange};
 },
});

export default ScriptListTable;
</script>
