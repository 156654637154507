<template>
  <sdDrawer
      ref="ScriptAddRef"
      :form="form"
      title="Script aanmaken"
      type="submit"
      btnText="Toevoegen"
      submitBtnText="Opslaan"
  >
    <FormValidationWrap>
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateScript">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Naam" name="name">
              <a-input v-model:value="form.name" placeholder=""/>
            </a-form-item>
          </a-col>
        </a-row>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button size="large" class="btn-signin" type="primary" html-type="submit"> Opslaan</a-button>
        </div>
      </a-form>
    </FormValidationWrap>
  </sdDrawer>
</template>
<script>
import {defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";

const ScriptAdd = defineComponent({
  name: 'ScriptAdd',
  components: {
    FormValidationWrap,
  },
  setup() {
    const ScriptAddRef = ref()
    const formRef = ref();
    const {dispatch} = useStore();
    const form = reactive({
      name: null,
    });
    const rules = {
      name: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };
    const closeDrawer = () => {
      ScriptAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getScripts')
    }
    const CreateScript = () => {
      dispatch('createScript', {value:form,close:closeDrawer});
    };
    return {
      form,
      rules,
      CreateScript,
      ScriptAddRef,
      formRef,
    };
  },
});

export default ScriptAdd;
</script>
